.border {
  border-width: 1px;
  border-style: solid;
}

.border-0 {
  border: none;
}

.border-top {
  border-top: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-left {
  border-left: 1px solid;
}

.border-right {
  border-right: 1px solid;
}

.border-color-transparent {
  border-color: transparent;
}

.border-l-color-transparent {
  border-left-color: transparent;
}

.border-r-color-transparent {
  border-right-color: transparent;
}

.border-b-color-transparent {
  border-bottom-color: transparent;
}

.border-t-color-transparent {
  border-top-color: transparent;
}

.border-x-color-transparent {
  border-left-color: transparent;
  border-right-color: transparent;
}

.border-y-color-transparent {
  border-top-color: transparent;
  border-bottom-color: transparent;
}

/**
 * Code generation below:
 */
.border-color-neutral-1 {
  border-color: var(--color-neutral-1);
}

.border-color-neutral-2 {
  border-color: var(--color-neutral-2);
}

.border-color-neutral-3 {
  border-color: var(--color-neutral-3);
}

.border-color-neutral-4 {
  border-color: var(--color-neutral-4);
}

.border-color-neutral-5 {
  border-color: var(--color-neutral-5);
}

.border-color-neutral-6 {
  border-color: var(--color-neutral-6);
}

.border-color-neutral-7 {
  border-color: var(--color-neutral-7);
}

.border-color-neutral-8 {
  border-color: var(--color-neutral-8);
}

.border-color-neutral-9 {
  border-color: var(--color-neutral-9);
}

.border-color-neutral-10 {
  border-color: var(--color-neutral-10);
}

.border-color-neutral-11 {
  border-color: var(--color-neutral-11);
}

.border-color-neutral-12 {
  border-color: var(--color-neutral-12);
}

.border-color-primary-1 {
  border-color: var(--color-primary-1);
}

.border-color-primary-2 {
  border-color: var(--color-primary-2);
}

.border-color-primary-3 {
  border-color: var(--color-primary-3);
}

.border-color-primary-4 {
  border-color: var(--color-primary-4);
}

.border-color-primary-5 {
  border-color: var(--color-primary-5);
}

.border-color-primary-6 {
  border-color: var(--color-primary-6);
}

.border-color-primary-7 {
  border-color: var(--color-primary-7);
}

.border-color-primary-8 {
  border-color: var(--color-primary-8);
}

.border-color-primary-9 {
  border-color: var(--color-primary-9);
}

.border-color-primary-10 {
  border-color: var(--color-primary-10);
}

.border-color-primary-11 {
  border-color: var(--color-primary-11);
}

.border-color-primary-12 {
  border-color: var(--color-primary-12);
}

.border-color-positive-1 {
  border-color: var(--color-positive-1);
}

.border-color-positive-2 {
  border-color: var(--color-positive-2);
}

.border-color-positive-3 {
  border-color: var(--color-positive-3);
}

.border-color-positive-4 {
  border-color: var(--color-positive-4);
}

.border-color-positive-5 {
  border-color: var(--color-positive-5);
}

.border-color-positive-6 {
  border-color: var(--color-positive-6);
}

.border-color-positive-7 {
  border-color: var(--color-positive-7);
}

.border-color-positive-8 {
  border-color: var(--color-positive-8);
}

.border-color-positive-9 {
  border-color: var(--color-positive-9);
}

.border-color-negative-1 {
  border-color: var(--color-negative-1);
}

.border-color-negative-2 {
  border-color: var(--color-negative-2);
}

.border-color-negative-3 {
  border-color: var(--color-negative-3);
}

.border-color-negative-4 {
  border-color: var(--color-negative-4);
}

.border-color-negative-5 {
  border-color: var(--color-negative-5);
}

.border-color-negative-6 {
  border-color: var(--color-negative-6);
}

.border-color-negative-7 {
  border-color: var(--color-negative-7);
}

.border-color-negative-8 {
  border-color: var(--color-negative-8);
}

.border-color-negative-9 {
  border-color: var(--color-negative-9);
}

.border-color-warning-1 {
  border-color: var(--color-warning-1);
}

.border-color-warning-2 {
  border-color: var(--color-warning-2);
}

.border-color-warning-3 {
  border-color: var(--color-warning-3);
}

.border-color-warning-4 {
  border-color: var(--color-warning-4);
}

.border-color-warning-5 {
  border-color: var(--color-warning-5);
}

.border-color-warning-6 {
  border-color: var(--color-warning-6);
}

.border-color-warning-7 {
  border-color: var(--color-warning-7);
}

.border-color-warning-8 {
  border-color: var(--color-warning-8);
}

.border-color-warning-9 {
  border-color: var(--color-warning-9);
}

.border-x-color-neutral-1 {
  border-left-color: var(--color-neutral-1);
  border-right-color: var(--color-neutral-1);
}

.border-x-color-neutral-2 {
  border-left-color: var(--color-neutral-2);
  border-right-color: var(--color-neutral-2);
}

.border-x-color-neutral-3 {
  border-left-color: var(--color-neutral-3);
  border-right-color: var(--color-neutral-3);
}

.border-x-color-neutral-4 {
  border-left-color: var(--color-neutral-4);
  border-right-color: var(--color-neutral-4);
}

.border-x-color-neutral-5 {
  border-left-color: var(--color-neutral-5);
  border-right-color: var(--color-neutral-5);
}

.border-x-color-neutral-6 {
  border-left-color: var(--color-neutral-6);
  border-right-color: var(--color-neutral-6);
}

.border-x-color-neutral-7 {
  border-left-color: var(--color-neutral-7);
  border-right-color: var(--color-neutral-7);
}

.border-x-color-neutral-8 {
  border-left-color: var(--color-neutral-8);
  border-right-color: var(--color-neutral-8);
}

.border-x-color-neutral-9 {
  border-left-color: var(--color-neutral-9);
  border-right-color: var(--color-neutral-9);
}

.border-x-color-neutral-10 {
  border-left-color: var(--color-neutral-10);
  border-right-color: var(--color-neutral-10);
}

.border-x-color-neutral-11 {
  border-left-color: var(--color-neutral-11);
  border-right-color: var(--color-neutral-11);
}

.border-x-color-neutral-12 {
  border-left-color: var(--color-neutral-12);
  border-right-color: var(--color-neutral-12);
}

.border-x-color-primary-1 {
  border-left-color: var(--color-primary-1);
  border-right-color: var(--color-primary-1);
}

.border-x-color-primary-2 {
  border-left-color: var(--color-primary-2);
  border-right-color: var(--color-primary-2);
}

.border-x-color-primary-3 {
  border-left-color: var(--color-primary-3);
  border-right-color: var(--color-primary-3);
}

.border-x-color-primary-4 {
  border-left-color: var(--color-primary-4);
  border-right-color: var(--color-primary-4);
}

.border-x-color-primary-5 {
  border-left-color: var(--color-primary-5);
  border-right-color: var(--color-primary-5);
}

.border-x-color-primary-6 {
  border-left-color: var(--color-primary-6);
  border-right-color: var(--color-primary-6);
}

.border-x-color-primary-7 {
  border-left-color: var(--color-primary-7);
  border-right-color: var(--color-primary-7);
}

.border-x-color-primary-8 {
  border-left-color: var(--color-primary-8);
  border-right-color: var(--color-primary-8);
}

.border-x-color-primary-9 {
  border-left-color: var(--color-primary-9);
  border-right-color: var(--color-primary-9);
}

.border-x-color-primary-10 {
  border-left-color: var(--color-primary-10);
  border-right-color: var(--color-primary-10);
}

.border-x-color-primary-11 {
  border-left-color: var(--color-primary-11);
  border-right-color: var(--color-primary-11);
}

.border-x-color-primary-12 {
  border-left-color: var(--color-primary-12);
  border-right-color: var(--color-primary-12);
}

.border-x-color-positive-1 {
  border-left-color: var(--color-positive-1);
  border-right-color: var(--color-positive-1);
}

.border-x-color-positive-2 {
  border-left-color: var(--color-positive-2);
  border-right-color: var(--color-positive-2);
}

.border-x-color-positive-3 {
  border-left-color: var(--color-positive-3);
  border-right-color: var(--color-positive-3);
}

.border-x-color-positive-4 {
  border-left-color: var(--color-positive-4);
  border-right-color: var(--color-positive-4);
}

.border-x-color-positive-5 {
  border-left-color: var(--color-positive-5);
  border-right-color: var(--color-positive-5);
}

.border-x-color-positive-6 {
  border-left-color: var(--color-positive-6);
  border-right-color: var(--color-positive-6);
}

.border-x-color-positive-7 {
  border-left-color: var(--color-positive-7);
  border-right-color: var(--color-positive-7);
}

.border-x-color-positive-8 {
  border-left-color: var(--color-positive-8);
  border-right-color: var(--color-positive-8);
}

.border-x-color-positive-9 {
  border-left-color: var(--color-positive-9);
  border-right-color: var(--color-positive-9);
}

.border-x-color-negative-1 {
  border-left-color: var(--color-negative-1);
  border-right-color: var(--color-negative-1);
}

.border-x-color-negative-2 {
  border-left-color: var(--color-negative-2);
  border-right-color: var(--color-negative-2);
}

.border-x-color-negative-3 {
  border-left-color: var(--color-negative-3);
  border-right-color: var(--color-negative-3);
}

.border-x-color-negative-4 {
  border-left-color: var(--color-negative-4);
  border-right-color: var(--color-negative-4);
}

.border-x-color-negative-5 {
  border-left-color: var(--color-negative-5);
  border-right-color: var(--color-negative-5);
}

.border-x-color-negative-6 {
  border-left-color: var(--color-negative-6);
  border-right-color: var(--color-negative-6);
}

.border-x-color-negative-7 {
  border-left-color: var(--color-negative-7);
  border-right-color: var(--color-negative-7);
}

.border-x-color-negative-8 {
  border-left-color: var(--color-negative-8);
  border-right-color: var(--color-negative-8);
}

.border-x-color-negative-9 {
  border-left-color: var(--color-negative-9);
  border-right-color: var(--color-negative-9);
}

.border-x-color-warning-1 {
  border-left-color: var(--color-warning-1);
  border-right-color: var(--color-warning-1);
}

.border-x-color-warning-2 {
  border-left-color: var(--color-warning-2);
  border-right-color: var(--color-warning-2);
}

.border-x-color-warning-3 {
  border-left-color: var(--color-warning-3);
  border-right-color: var(--color-warning-3);
}

.border-x-color-warning-4 {
  border-left-color: var(--color-warning-4);
  border-right-color: var(--color-warning-4);
}

.border-x-color-warning-5 {
  border-left-color: var(--color-warning-5);
  border-right-color: var(--color-warning-5);
}

.border-x-color-warning-6 {
  border-left-color: var(--color-warning-6);
  border-right-color: var(--color-warning-6);
}

.border-x-color-warning-7 {
  border-left-color: var(--color-warning-7);
  border-right-color: var(--color-warning-7);
}

.border-x-color-warning-8 {
  border-left-color: var(--color-warning-8);
  border-right-color: var(--color-warning-8);
}

.border-x-color-warning-9 {
  border-left-color: var(--color-warning-9);
  border-right-color: var(--color-warning-9);
}

.border-y-color-neutral-1 {
  border-top-color: var(--color-neutral-1);
  border-bottom-color: var(--color-neutral-1);
}

.border-y-color-neutral-2 {
  border-top-color: var(--color-neutral-2);
  border-bottom-color: var(--color-neutral-2);
}

.border-y-color-neutral-3 {
  border-top-color: var(--color-neutral-3);
  border-bottom-color: var(--color-neutral-3);
}

.border-y-color-neutral-4 {
  border-top-color: var(--color-neutral-4);
  border-bottom-color: var(--color-neutral-4);
}

.border-y-color-neutral-5 {
  border-top-color: var(--color-neutral-5);
  border-bottom-color: var(--color-neutral-5);
}

.border-y-color-neutral-6 {
  border-top-color: var(--color-neutral-6);
  border-bottom-color: var(--color-neutral-6);
}

.border-y-color-neutral-7 {
  border-top-color: var(--color-neutral-7);
  border-bottom-color: var(--color-neutral-7);
}

.border-y-color-neutral-8 {
  border-top-color: var(--color-neutral-8);
  border-bottom-color: var(--color-neutral-8);
}

.border-y-color-neutral-9 {
  border-top-color: var(--color-neutral-9);
  border-bottom-color: var(--color-neutral-9);
}

.border-y-color-neutral-10 {
  border-top-color: var(--color-neutral-10);
  border-bottom-color: var(--color-neutral-10);
}

.border-y-color-neutral-11 {
  border-top-color: var(--color-neutral-11);
  border-bottom-color: var(--color-neutral-11);
}

.border-y-color-neutral-12 {
  border-top-color: var(--color-neutral-12);
  border-bottom-color: var(--color-neutral-12);
}

.border-y-color-primary-1 {
  border-top-color: var(--color-primary-1);
  border-bottom-color: var(--color-primary-1);
}

.border-y-color-primary-2 {
  border-top-color: var(--color-primary-2);
  border-bottom-color: var(--color-primary-2);
}

.border-y-color-primary-3 {
  border-top-color: var(--color-primary-3);
  border-bottom-color: var(--color-primary-3);
}

.border-y-color-primary-4 {
  border-top-color: var(--color-primary-4);
  border-bottom-color: var(--color-primary-4);
}

.border-y-color-primary-5 {
  border-top-color: var(--color-primary-5);
  border-bottom-color: var(--color-primary-5);
}

.border-y-color-primary-6 {
  border-top-color: var(--color-primary-6);
  border-bottom-color: var(--color-primary-6);
}

.border-y-color-primary-7 {
  border-top-color: var(--color-primary-7);
  border-bottom-color: var(--color-primary-7);
}

.border-y-color-primary-8 {
  border-top-color: var(--color-primary-8);
  border-bottom-color: var(--color-primary-8);
}

.border-y-color-primary-9 {
  border-top-color: var(--color-primary-9);
  border-bottom-color: var(--color-primary-9);
}

.border-y-color-primary-10 {
  border-top-color: var(--color-primary-10);
  border-bottom-color: var(--color-primary-10);
}

.border-y-color-primary-11 {
  border-top-color: var(--color-primary-11);
  border-bottom-color: var(--color-primary-11);
}

.border-y-color-primary-12 {
  border-top-color: var(--color-primary-12);
  border-bottom-color: var(--color-primary-12);
}

.border-y-color-positive-1 {
  border-top-color: var(--color-positive-1);
  border-bottom-color: var(--color-positive-1);
}

.border-y-color-positive-2 {
  border-top-color: var(--color-positive-2);
  border-bottom-color: var(--color-positive-2);
}

.border-y-color-positive-3 {
  border-top-color: var(--color-positive-3);
  border-bottom-color: var(--color-positive-3);
}

.border-y-color-positive-4 {
  border-top-color: var(--color-positive-4);
  border-bottom-color: var(--color-positive-4);
}

.border-y-color-positive-5 {
  border-top-color: var(--color-positive-5);
  border-bottom-color: var(--color-positive-5);
}

.border-y-color-positive-6 {
  border-top-color: var(--color-positive-6);
  border-bottom-color: var(--color-positive-6);
}

.border-y-color-positive-7 {
  border-top-color: var(--color-positive-7);
  border-bottom-color: var(--color-positive-7);
}

.border-y-color-positive-8 {
  border-top-color: var(--color-positive-8);
  border-bottom-color: var(--color-positive-8);
}

.border-y-color-positive-9 {
  border-top-color: var(--color-positive-9);
  border-bottom-color: var(--color-positive-9);
}

.border-y-color-negative-1 {
  border-top-color: var(--color-negative-1);
  border-bottom-color: var(--color-negative-1);
}

.border-y-color-negative-2 {
  border-top-color: var(--color-negative-2);
  border-bottom-color: var(--color-negative-2);
}

.border-y-color-negative-3 {
  border-top-color: var(--color-negative-3);
  border-bottom-color: var(--color-negative-3);
}

.border-y-color-negative-4 {
  border-top-color: var(--color-negative-4);
  border-bottom-color: var(--color-negative-4);
}

.border-y-color-negative-5 {
  border-top-color: var(--color-negative-5);
  border-bottom-color: var(--color-negative-5);
}

.border-y-color-negative-6 {
  border-top-color: var(--color-negative-6);
  border-bottom-color: var(--color-negative-6);
}

.border-y-color-negative-7 {
  border-top-color: var(--color-negative-7);
  border-bottom-color: var(--color-negative-7);
}

.border-y-color-negative-8 {
  border-top-color: var(--color-negative-8);
  border-bottom-color: var(--color-negative-8);
}

.border-y-color-negative-9 {
  border-top-color: var(--color-negative-9);
  border-bottom-color: var(--color-negative-9);
}

.border-y-color-warning-1 {
  border-top-color: var(--color-warning-1);
  border-bottom-color: var(--color-warning-1);
}

.border-y-color-warning-2 {
  border-top-color: var(--color-warning-2);
  border-bottom-color: var(--color-warning-2);
}

.border-y-color-warning-3 {
  border-top-color: var(--color-warning-3);
  border-bottom-color: var(--color-warning-3);
}

.border-y-color-warning-4 {
  border-top-color: var(--color-warning-4);
  border-bottom-color: var(--color-warning-4);
}

.border-y-color-warning-5 {
  border-top-color: var(--color-warning-5);
  border-bottom-color: var(--color-warning-5);
}

.border-y-color-warning-6 {
  border-top-color: var(--color-warning-6);
  border-bottom-color: var(--color-warning-6);
}

.border-y-color-warning-7 {
  border-top-color: var(--color-warning-7);
  border-bottom-color: var(--color-warning-7);
}

.border-y-color-warning-8 {
  border-top-color: var(--color-warning-8);
  border-bottom-color: var(--color-warning-8);
}

.border-y-color-warning-9 {
  border-top-color: var(--color-warning-9);
  border-bottom-color: var(--color-warning-9);
}

.border-l-color-neutral-1 {
  border-left-color: var(--color-neutral-1);
}

.border-l-color-neutral-2 {
  border-left-color: var(--color-neutral-2);
}

.border-l-color-neutral-3 {
  border-left-color: var(--color-neutral-3);
}

.border-l-color-neutral-4 {
  border-left-color: var(--color-neutral-4);
}

.border-l-color-neutral-5 {
  border-left-color: var(--color-neutral-5);
}

.border-l-color-neutral-6 {
  border-left-color: var(--color-neutral-6);
}

.border-l-color-neutral-7 {
  border-left-color: var(--color-neutral-7);
}

.border-l-color-neutral-8 {
  border-left-color: var(--color-neutral-8);
}

.border-l-color-neutral-9 {
  border-left-color: var(--color-neutral-9);
}

.border-l-color-neutral-10 {
  border-left-color: var(--color-neutral-10);
}

.border-l-color-neutral-11 {
  border-left-color: var(--color-neutral-11);
}

.border-l-color-neutral-12 {
  border-left-color: var(--color-neutral-12);
}

.border-l-color-primary-1 {
  border-left-color: var(--color-primary-1);
}

.border-l-color-primary-2 {
  border-left-color: var(--color-primary-2);
}

.border-l-color-primary-3 {
  border-left-color: var(--color-primary-3);
}

.border-l-color-primary-4 {
  border-left-color: var(--color-primary-4);
}

.border-l-color-primary-5 {
  border-left-color: var(--color-primary-5);
}

.border-l-color-primary-6 {
  border-left-color: var(--color-primary-6);
}

.border-l-color-primary-7 {
  border-left-color: var(--color-primary-7);
}

.border-l-color-primary-8 {
  border-left-color: var(--color-primary-8);
}

.border-l-color-primary-9 {
  border-left-color: var(--color-primary-9);
}

.border-l-color-primary-10 {
  border-left-color: var(--color-primary-10);
}

.border-l-color-primary-11 {
  border-left-color: var(--color-primary-11);
}

.border-l-color-primary-12 {
  border-left-color: var(--color-primary-12);
}

.border-l-color-positive-1 {
  border-left-color: var(--color-positive-1);
}

.border-l-color-positive-2 {
  border-left-color: var(--color-positive-2);
}

.border-l-color-positive-3 {
  border-left-color: var(--color-positive-3);
}

.border-l-color-positive-4 {
  border-left-color: var(--color-positive-4);
}

.border-l-color-positive-5 {
  border-left-color: var(--color-positive-5);
}

.border-l-color-positive-6 {
  border-left-color: var(--color-positive-6);
}

.border-l-color-positive-7 {
  border-left-color: var(--color-positive-7);
}

.border-l-color-positive-8 {
  border-left-color: var(--color-positive-8);
}

.border-l-color-positive-9 {
  border-left-color: var(--color-positive-9);
}

.border-l-color-negative-1 {
  border-left-color: var(--color-negative-1);
}

.border-l-color-negative-2 {
  border-left-color: var(--color-negative-2);
}

.border-l-color-negative-3 {
  border-left-color: var(--color-negative-3);
}

.border-l-color-negative-4 {
  border-left-color: var(--color-negative-4);
}

.border-l-color-negative-5 {
  border-left-color: var(--color-negative-5);
}

.border-l-color-negative-6 {
  border-left-color: var(--color-negative-6);
}

.border-l-color-negative-7 {
  border-left-color: var(--color-negative-7);
}

.border-l-color-negative-8 {
  border-left-color: var(--color-negative-8);
}

.border-l-color-negative-9 {
  border-left-color: var(--color-negative-9);
}

.border-l-color-warning-1 {
  border-left-color: var(--color-warning-1);
}

.border-l-color-warning-2 {
  border-left-color: var(--color-warning-2);
}

.border-l-color-warning-3 {
  border-left-color: var(--color-warning-3);
}

.border-l-color-warning-4 {
  border-left-color: var(--color-warning-4);
}

.border-l-color-warning-5 {
  border-left-color: var(--color-warning-5);
}

.border-l-color-warning-6 {
  border-left-color: var(--color-warning-6);
}

.border-l-color-warning-7 {
  border-left-color: var(--color-warning-7);
}

.border-l-color-warning-8 {
  border-left-color: var(--color-warning-8);
}

.border-l-color-warning-9 {
  border-left-color: var(--color-warning-9);
}

.border-r-color-neutral-1 {
  border-right-color: var(--color-neutral-1);
}

.border-r-color-neutral-2 {
  border-right-color: var(--color-neutral-2);
}

.border-r-color-neutral-3 {
  border-right-color: var(--color-neutral-3);
}

.border-r-color-neutral-4 {
  border-right-color: var(--color-neutral-4);
}

.border-r-color-neutral-5 {
  border-right-color: var(--color-neutral-5);
}

.border-r-color-neutral-6 {
  border-right-color: var(--color-neutral-6);
}

.border-r-color-neutral-7 {
  border-right-color: var(--color-neutral-7);
}

.border-r-color-neutral-8 {
  border-right-color: var(--color-neutral-8);
}

.border-r-color-neutral-9 {
  border-right-color: var(--color-neutral-9);
}

.border-r-color-neutral-10 {
  border-right-color: var(--color-neutral-10);
}

.border-r-color-neutral-11 {
  border-right-color: var(--color-neutral-11);
}

.border-r-color-neutral-12 {
  border-right-color: var(--color-neutral-12);
}

.border-r-color-primary-1 {
  border-right-color: var(--color-primary-1);
}

.border-r-color-primary-2 {
  border-right-color: var(--color-primary-2);
}

.border-r-color-primary-3 {
  border-right-color: var(--color-primary-3);
}

.border-r-color-primary-4 {
  border-right-color: var(--color-primary-4);
}

.border-r-color-primary-5 {
  border-right-color: var(--color-primary-5);
}

.border-r-color-primary-6 {
  border-right-color: var(--color-primary-6);
}

.border-r-color-primary-7 {
  border-right-color: var(--color-primary-7);
}

.border-r-color-primary-8 {
  border-right-color: var(--color-primary-8);
}

.border-r-color-primary-9 {
  border-right-color: var(--color-primary-9);
}

.border-r-color-primary-10 {
  border-right-color: var(--color-primary-10);
}

.border-r-color-primary-11 {
  border-right-color: var(--color-primary-11);
}

.border-r-color-primary-12 {
  border-right-color: var(--color-primary-12);
}

.border-r-color-positive-1 {
  border-right-color: var(--color-positive-1);
}

.border-r-color-positive-2 {
  border-right-color: var(--color-positive-2);
}

.border-r-color-positive-3 {
  border-right-color: var(--color-positive-3);
}

.border-r-color-positive-4 {
  border-right-color: var(--color-positive-4);
}

.border-r-color-positive-5 {
  border-right-color: var(--color-positive-5);
}

.border-r-color-positive-6 {
  border-right-color: var(--color-positive-6);
}

.border-r-color-positive-7 {
  border-right-color: var(--color-positive-7);
}

.border-r-color-positive-8 {
  border-right-color: var(--color-positive-8);
}

.border-r-color-positive-9 {
  border-right-color: var(--color-positive-9);
}

.border-r-color-negative-1 {
  border-right-color: var(--color-negative-1);
}

.border-r-color-negative-2 {
  border-right-color: var(--color-negative-2);
}

.border-r-color-negative-3 {
  border-right-color: var(--color-negative-3);
}

.border-r-color-negative-4 {
  border-right-color: var(--color-negative-4);
}

.border-r-color-negative-5 {
  border-right-color: var(--color-negative-5);
}

.border-r-color-negative-6 {
  border-right-color: var(--color-negative-6);
}

.border-r-color-negative-7 {
  border-right-color: var(--color-negative-7);
}

.border-r-color-negative-8 {
  border-right-color: var(--color-negative-8);
}

.border-r-color-negative-9 {
  border-right-color: var(--color-negative-9);
}

.border-r-color-warning-1 {
  border-right-color: var(--color-warning-1);
}

.border-r-color-warning-2 {
  border-right-color: var(--color-warning-2);
}

.border-r-color-warning-3 {
  border-right-color: var(--color-warning-3);
}

.border-r-color-warning-4 {
  border-right-color: var(--color-warning-4);
}

.border-r-color-warning-5 {
  border-right-color: var(--color-warning-5);
}

.border-r-color-warning-6 {
  border-right-color: var(--color-warning-6);
}

.border-r-color-warning-7 {
  border-right-color: var(--color-warning-7);
}

.border-r-color-warning-8 {
  border-right-color: var(--color-warning-8);
}

.border-r-color-warning-9 {
  border-right-color: var(--color-warning-9);
}

.border-t-color-neutral-1 {
  border-top-color: var(--color-neutral-1);
}

.border-t-color-neutral-2 {
  border-top-color: var(--color-neutral-2);
}

.border-t-color-neutral-3 {
  border-top-color: var(--color-neutral-3);
}

.border-t-color-neutral-4 {
  border-top-color: var(--color-neutral-4);
}

.border-t-color-neutral-5 {
  border-top-color: var(--color-neutral-5);
}

.border-t-color-neutral-6 {
  border-top-color: var(--color-neutral-6);
}

.border-t-color-neutral-7 {
  border-top-color: var(--color-neutral-7);
}

.border-t-color-neutral-8 {
  border-top-color: var(--color-neutral-8);
}

.border-t-color-neutral-9 {
  border-top-color: var(--color-neutral-9);
}

.border-t-color-neutral-10 {
  border-top-color: var(--color-neutral-10);
}

.border-t-color-neutral-11 {
  border-top-color: var(--color-neutral-11);
}

.border-t-color-neutral-12 {
  border-top-color: var(--color-neutral-12);
}

.border-t-color-primary-1 {
  border-top-color: var(--color-primary-1);
}

.border-t-color-primary-2 {
  border-top-color: var(--color-primary-2);
}

.border-t-color-primary-3 {
  border-top-color: var(--color-primary-3);
}

.border-t-color-primary-4 {
  border-top-color: var(--color-primary-4);
}

.border-t-color-primary-5 {
  border-top-color: var(--color-primary-5);
}

.border-t-color-primary-6 {
  border-top-color: var(--color-primary-6);
}

.border-t-color-primary-7 {
  border-top-color: var(--color-primary-7);
}

.border-t-color-primary-8 {
  border-top-color: var(--color-primary-8);
}

.border-t-color-primary-9 {
  border-top-color: var(--color-primary-9);
}

.border-t-color-primary-10 {
  border-top-color: var(--color-primary-10);
}

.border-t-color-primary-11 {
  border-top-color: var(--color-primary-11);
}

.border-t-color-primary-12 {
  border-top-color: var(--color-primary-12);
}

.border-t-color-positive-1 {
  border-top-color: var(--color-positive-1);
}

.border-t-color-positive-2 {
  border-top-color: var(--color-positive-2);
}

.border-t-color-positive-3 {
  border-top-color: var(--color-positive-3);
}

.border-t-color-positive-4 {
  border-top-color: var(--color-positive-4);
}

.border-t-color-positive-5 {
  border-top-color: var(--color-positive-5);
}

.border-t-color-positive-6 {
  border-top-color: var(--color-positive-6);
}

.border-t-color-positive-7 {
  border-top-color: var(--color-positive-7);
}

.border-t-color-positive-8 {
  border-top-color: var(--color-positive-8);
}

.border-t-color-positive-9 {
  border-top-color: var(--color-positive-9);
}

.border-t-color-negative-1 {
  border-top-color: var(--color-negative-1);
}

.border-t-color-negative-2 {
  border-top-color: var(--color-negative-2);
}

.border-t-color-negative-3 {
  border-top-color: var(--color-negative-3);
}

.border-t-color-negative-4 {
  border-top-color: var(--color-negative-4);
}

.border-t-color-negative-5 {
  border-top-color: var(--color-negative-5);
}

.border-t-color-negative-6 {
  border-top-color: var(--color-negative-6);
}

.border-t-color-negative-7 {
  border-top-color: var(--color-negative-7);
}

.border-t-color-negative-8 {
  border-top-color: var(--color-negative-8);
}

.border-t-color-negative-9 {
  border-top-color: var(--color-negative-9);
}

.border-t-color-warning-1 {
  border-top-color: var(--color-warning-1);
}

.border-t-color-warning-2 {
  border-top-color: var(--color-warning-2);
}

.border-t-color-warning-3 {
  border-top-color: var(--color-warning-3);
}

.border-t-color-warning-4 {
  border-top-color: var(--color-warning-4);
}

.border-t-color-warning-5 {
  border-top-color: var(--color-warning-5);
}

.border-t-color-warning-6 {
  border-top-color: var(--color-warning-6);
}

.border-t-color-warning-7 {
  border-top-color: var(--color-warning-7);
}

.border-t-color-warning-8 {
  border-top-color: var(--color-warning-8);
}

.border-t-color-warning-9 {
  border-top-color: var(--color-warning-9);
}

.border-b-color-neutral-1 {
  border-bottom-color: var(--color-neutral-1);
}

.border-b-color-neutral-2 {
  border-bottom-color: var(--color-neutral-2);
}

.border-b-color-neutral-3 {
  border-bottom-color: var(--color-neutral-3);
}

.border-b-color-neutral-4 {
  border-bottom-color: var(--color-neutral-4);
}

.border-b-color-neutral-5 {
  border-bottom-color: var(--color-neutral-5);
}

.border-b-color-neutral-6 {
  border-bottom-color: var(--color-neutral-6);
}

.border-b-color-neutral-7 {
  border-bottom-color: var(--color-neutral-7);
}

.border-b-color-neutral-8 {
  border-bottom-color: var(--color-neutral-8);
}

.border-b-color-neutral-9 {
  border-bottom-color: var(--color-neutral-9);
}

.border-b-color-neutral-10 {
  border-bottom-color: var(--color-neutral-10);
}

.border-b-color-neutral-11 {
  border-bottom-color: var(--color-neutral-11);
}

.border-b-color-neutral-12 {
  border-bottom-color: var(--color-neutral-12);
}

.border-b-color-primary-1 {
  border-bottom-color: var(--color-primary-1);
}

.border-b-color-primary-2 {
  border-bottom-color: var(--color-primary-2);
}

.border-b-color-primary-3 {
  border-bottom-color: var(--color-primary-3);
}

.border-b-color-primary-4 {
  border-bottom-color: var(--color-primary-4);
}

.border-b-color-primary-5 {
  border-bottom-color: var(--color-primary-5);
}

.border-b-color-primary-6 {
  border-bottom-color: var(--color-primary-6);
}

.border-b-color-primary-7 {
  border-bottom-color: var(--color-primary-7);
}

.border-b-color-primary-8 {
  border-bottom-color: var(--color-primary-8);
}

.border-b-color-primary-9 {
  border-bottom-color: var(--color-primary-9);
}

.border-b-color-primary-10 {
  border-bottom-color: var(--color-primary-10);
}

.border-b-color-primary-11 {
  border-bottom-color: var(--color-primary-11);
}

.border-b-color-primary-12 {
  border-bottom-color: var(--color-primary-12);
}

.border-b-color-positive-1 {
  border-bottom-color: var(--color-positive-1);
}

.border-b-color-positive-2 {
  border-bottom-color: var(--color-positive-2);
}

.border-b-color-positive-3 {
  border-bottom-color: var(--color-positive-3);
}

.border-b-color-positive-4 {
  border-bottom-color: var(--color-positive-4);
}

.border-b-color-positive-5 {
  border-bottom-color: var(--color-positive-5);
}

.border-b-color-positive-6 {
  border-bottom-color: var(--color-positive-6);
}

.border-b-color-positive-7 {
  border-bottom-color: var(--color-positive-7);
}

.border-b-color-positive-8 {
  border-bottom-color: var(--color-positive-8);
}

.border-b-color-positive-9 {
  border-bottom-color: var(--color-positive-9);
}

.border-b-color-negative-1 {
  border-bottom-color: var(--color-negative-1);
}

.border-b-color-negative-2 {
  border-bottom-color: var(--color-negative-2);
}

.border-b-color-negative-3 {
  border-bottom-color: var(--color-negative-3);
}

.border-b-color-negative-4 {
  border-bottom-color: var(--color-negative-4);
}

.border-b-color-negative-5 {
  border-bottom-color: var(--color-negative-5);
}

.border-b-color-negative-6 {
  border-bottom-color: var(--color-negative-6);
}

.border-b-color-negative-7 {
  border-bottom-color: var(--color-negative-7);
}

.border-b-color-negative-8 {
  border-bottom-color: var(--color-negative-8);
}

.border-b-color-negative-9 {
  border-bottom-color: var(--color-negative-9);
}

.border-b-color-warning-1 {
  border-bottom-color: var(--color-warning-1);
}

.border-b-color-warning-2 {
  border-bottom-color: var(--color-warning-2);
}

.border-b-color-warning-3 {
  border-bottom-color: var(--color-warning-3);
}

.border-b-color-warning-4 {
  border-bottom-color: var(--color-warning-4);
}

.border-b-color-warning-5 {
  border-bottom-color: var(--color-warning-5);
}

.border-b-color-warning-6 {
  border-bottom-color: var(--color-warning-6);
}

.border-b-color-warning-7 {
  border-bottom-color: var(--color-warning-7);
}

.border-b-color-warning-8 {
  border-bottom-color: var(--color-warning-8);
}

.border-b-color-warning-9 {
  border-bottom-color: var(--color-warning-9);
}
