.pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default;
}

.wrap-word {
  word-wrap: break-word;
  hyphens: auto;
}

.select-none {
  user-select: none;
}

.pointer-none {
  pointer-events: none;
}
