/*
 * SIZING
 * VISIBILITY
 * GRID
 * FLEX
 * DISPLAY
 * POSITION
 * OVERFLOW
*/

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-content {
  min-width: fit-content;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-half {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto !important;
}

.hidden {
  display: none;
}

.hide-empty:empty {
  display: none;
}

.grid {
  display: grid;
}

.grid-col-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-gap-0 {
  grid-gap: 0;
}

.grid-gap-4 {
  grid-gap: 0.4rem;
}

.grid-gap-8 {
  grid-gap: 0.8rem;
}

.grid-gap-12 {
  grid-gap: 1.2rem;
}

.grid-gap-16 {
  grid-gap: 1.6rem;
}

.grid-gap-20 {
  grid-gap: 2rem;
}

.grid-gap-24 {
  grid-gap: 2.4rem;
}

.grid-gap-28 {
  grid-gap: 2.8rem;
}

.grid-gap-32 {
  grid-gap: 3.2rem;
}

.grid-gap-40 {
  grid-gap: 4rem;
}

.grid-gap-44 {
  grid-gap: 4.4rem;
}

.grid-gap-48 {
  grid-gap: 4.8rem;
}

.grid-gap-52 {
  grid-gap: 5.2rem;
}

.grid-gap-56 {
  grid-gap: 5.6rem;
}

.flex-1 {
  flex: 1 1 0;
}

.flex-2 {
  flex: 2 1 0;
}

.flex-3 {
  flex: 3 1 0;
}

.flex-5 {
  flex: 5 1 0;
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 1px;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-basis-0 {
  flex-basis: 0%;
}

.flex-basis-10 {
  flex-basis: 10%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-30 {
  flex-basis: 30%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

.flex-basis-70 {
  flex-basis: 70%;
}

.flex-basis-80 {
  flex-basis: 80%;
}

.flex-basis-90 {
  flex-basis: 90%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.no-shrink {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-self-end {
  justify-self: flex-end;
}

.justify-stretch {
  justify-content: stretch;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.webkit-box {
  display: -webkit-box;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.vertical-middle {
  vertical-align: middle;
}

.vartical-baseline {
  vertical-align: baseline;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-25 {
  left: 25%;
}

.left-50 {
  left: 50%;
}

.left-75 {
  left: 75%;
}

.left-100 {
  left: 100%;
}

.right-0 {
  right: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.top-inherit {
  top: inherit;
}

.bottom-100 {
  bottom: 100%;
}

.bottom-inherit {
  bottom: inherit;
}

.left-100 {
  left: 100%;
}

.right-100 {
  right: 100%;
}

.transform-50 {
  transform: translate(-50%, 0);
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-auto {
  overflow: auto;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:not(.block) {
    display: -webkit-box;
  }
}

.overflow-ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.overflow-ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.v-visible {
  visibility: visible;
}

.v-hidden {
  visibility: hidden;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}
