.hover-bg-neutral-1:hover {
  background-color: var(--color-neutral-1);
}

.hover-bg-neutral-2:hover {
  background-color: var(--color-neutral-2);
}

.hover-bg-neutral-3:hover {
  background-color: var(--color-neutral-3);
}

.hover-bg-neutral-4:hover {
  background-color: var(--color-neutral-4);
}

.hover-bg-neutral-5:hover {
  background-color: var(--color-neutral-5);
}

.hover-bg-neutral-6:hover {
  background-color: var(--color-neutral-6);
}

.hover-bg-neutral-7:hover {
  background-color: var(--color-neutral-7);
}

.hover-bg-neutral-8:hover {
  background-color: var(--color-neutral-8);
}

.hover-bg-neutral-9:hover {
  background-color: var(--color-neutral-9);
}

.hover-bg-neutral-10:hover {
  background-color: var(--color-neutral-10);
}

.hover-bg-neutral-11:hover {
  background-color: var(--color-neutral-11);
}

.hover-bg-neutral-12:hover {
  background-color: var(--color-neutral-12);
}

.hover-bg-primary-1:hover {
  background-color: var(--color-primary-1);
}

.hover-bg-primary-2:hover {
  background-color: var(--color-primary-2);
}

.hover-bg-primary-3:hover {
  background-color: var(--color-primary-3);
}

.hover-bg-primary-4:hover {
  background-color: var(--color-primary-4);
}

.hover-bg-primary-5:hover {
  background-color: var(--color-primary-5);
}

.hover-bg-primary-6:hover {
  background-color: var(--color-primary-6);
}

.hover-bg-primary-7:hover {
  background-color: var(--color-primary-7);
}

.hover-bg-primary-8:hover {
  background-color: var(--color-primary-8);
}

.hover-bg-primary-9:hover {
  background-color: var(--color-primary-9);
}

.hover-bg-primary-10:hover {
  background-color: var(--color-primary-10);
}

.hover-bg-primary-11:hover {
  background-color: var(--color-primary-11);
}

.hover-bg-primary-12:hover {
  background-color: var(--color-primary-12);
}

.hover-bg-positive-1:hover {
  background-color: var(--color-positive-1);
}

.hover-bg-positive-2:hover {
  background-color: var(--color-positive-2);
}

.hover-bg-positive-3:hover {
  background-color: var(--color-positive-3);
}

.hover-bg-positive-4:hover {
  background-color: var(--color-positive-4);
}

.hover-bg-positive-5:hover {
  background-color: var(--color-positive-5);
}

.hover-bg-positive-6:hover {
  background-color: var(--color-positive-6);
}

.hover-bg-positive-7:hover {
  background-color: var(--color-positive-7);
}

.hover-bg-positive-8:hover {
  background-color: var(--color-positive-8);
}

.hover-bg-positive-9:hover {
  background-color: var(--color-positive-9);
}

.hover-bg-negative-1:hover {
  background-color: var(--color-negative-1);
}

.hover-bg-negative-2:hover {
  background-color: var(--color-negative-2);
}

.hover-bg-negative-3:hover {
  background-color: var(--color-negative-3);
}

.hover-bg-negative-4:hover {
  background-color: var(--color-negative-4);
}

.hover-bg-negative-5:hover {
  background-color: var(--color-negative-5);
}

.hover-bg-negative-6:hover {
  background-color: var(--color-negative-6);
}

.hover-bg-negative-7:hover {
  background-color: var(--color-negative-7);
}

.hover-bg-negative-8:hover {
  background-color: var(--color-negative-8);
}

.hover-bg-negative-9:hover {
  background-color: var(--color-negative-9);
}

.hover-bg-warning-1:hover {
  background-color: var(--color-warning-1);
}

.hover-bg-warning-2:hover {
  background-color: var(--color-warning-2);
}

.hover-bg-warning-3:hover {
  background-color: var(--color-warning-3);
}

.hover-bg-warning-4:hover {
  background-color: var(--color-warning-4);
}

.hover-bg-warning-5:hover {
  background-color: var(--color-warning-5);
}

.hover-bg-warning-6:hover {
  background-color: var(--color-warning-6);
}

.hover-bg-warning-7:hover {
  background-color: var(--color-warning-7);
}

.hover-bg-warning-8:hover {
  background-color: var(--color-warning-8);
}

.hover-bg-warning-9:hover {
  background-color: var(--color-warning-9);
}
