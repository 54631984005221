:root {
  --color-neutral-1: #eaedee;
  --color-neutral-2: #e4eaec;
  --color-neutral-3: #c6cfd8;
  --color-neutral-4: #8f96a3;
  --color-neutral-5: #6a7484;
  --color-neutral-6: #525a67;
  --color-neutral-7: #434d59;
  --color-neutral-8: #333b48;
  --color-neutral-9: #29313e;
  --color-neutral-10: #1f2630;
  --color-neutral-11: #171d25;
  --color-neutral-12: #10151b;

  --color-primary-1: #efffc4;
  --color-primary-2: #e3faa2;
  --color-primary-3: #dbff75;
  --color-primary-4: #c4f538;
  --color-primary-5: #aade16;
  --color-primary-6: #8cb617;
  --color-primary-7: #6c8f07;
  --color-primary-8: #526f04;
  --color-primary-9: #3c5004;
  --color-primary-10: #2d3c03;
  --color-primary-11: #202903;
  --color-primary-12: #1a2201;

  --color-positive-1: #bfffe8;
  --color-positive-2: #9fffdd;
  --color-positive-3: #76f1c5;
  --color-positive-4: #4be1ac;
  --color-positive-5: #36d39b;
  --color-positive-6: #2eab7e;
  --color-positive-7: #207658;
  --color-positive-8: #184938;
  --color-positive-9: #103628;

  --color-negative-1: #fabdbd;
  --color-negative-2: #ff9797;
  --color-negative-3: #ff8282;
  --color-negative-4: #ff6c6c;
  --color-negative-5: #f85757;
  --color-negative-6: #eb4b4b;
  --color-negative-7: #bc3535;
  --color-negative-8: #852323;
  --color-negative-9: #591e1e;

  --color-warning-1: #fff9eb;
  --color-warning-2: #fff1d6;
  --color-warning-3: #feeac1;
  --color-warning-4: #fedc98;
  --color-warning-5: #ffc147;
  --color-warning-6: #ffb41e;
  --color-warning-7: #d09519;
  --color-warning-8: #a37314;
  --color-warning-9: #75520e;
}
