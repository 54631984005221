@import 'src/scss/mixins';

.container {
  min-height: 100dvh;
}

.leftPart {
  flex-basis: 70%;
}

.rightPart {
  top: 5.6rem;
}

.preview {
  aspect-ratio: 16 / 9;
}

.post {
  box-shadow:
    0px 14px 64px -4px rgba(0, 0, 0, 0.12),
    0px 8px 22px -6px rgba(0, 0, 0, 0.12);

  @include breakpoint-lg {
    max-width: 42.4rem;
  }
}

.scrollUp {
  width: 4.4rem;
  height: 4.4rem;
}

.cmsArticle {
  p,
  li {
    color: #a8aeb8;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 150%;

    strong {
      font-weight: 600;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 3.2rem 0 1.6rem;
    color: var(--color-neutral-1);
    font-size: 2rem;
    font-weight: 400;
    line-height: 150%;

    strong {
      font-weight: inherit;
    }
  }

  figcaption {
    margin-top: -1.6rem;
  }

  img,
  iframe,
  video {
    width: 100%;
    height: auto;
    margin: 1.6rem 0;
  }

  a {
    color: var(--color-primary-5);
    text-decoration: underline;
  }

  figure {
    padding: 0 !important;
  }
}

.footer {
  margin-top: 10rem;
}

.breadcrumb {
  &:hover {
    color: var(--color-primary-5);
  }
}
