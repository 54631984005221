@import 'src/scss/mixins';

.article {
  box-shadow:
    0px 14px 64px -4px rgba(0, 0, 0, 0.12),
    0px 8px 22px -6px rgba(0, 0, 0, 0.12);

  @include breakpoint-md {
    max-width: 42.4rem;
  }
}

.preview {
  aspect-ratio: 16 / 9;
}

.image {
  aspect-ratio: 16 / 9;
  background-size: contain;
}
