@mixin breakpoint-sm {
  @media (min-width: 0px) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (min-width: 1920px) {
    @content;
  }
}

t .xs {
  height: 2rem;
}

.sm {
  height: 2.4rem;
}

.md {
  height: 3.2rem;
}

.lg {
  height: 4rem;
}

.xl {
  height: 6rem;

  @include breakpoint-md {
    height: 5.2rem;
  }
}

.hover:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.active:active {
  opacity: 0.8;
}

.color {
  &White {
    svg {
      stroke: var(--color-neutral-1);
    }
  }
  &Neutral {
    svg {
      stroke: var(--color-neutral-10);
    }
  }

  &Primary {
    svg {
      stroke: var(--color-primary-5);
    }
  }
}
