@import url('./scss/_mixins.scss');
@import url('./scss/_varsColors.scss');
@import url('./scss/_backgroundColors.scss');
@import url('./scss/_layout.scss');
@import url('./scss/_typography.scss');
@import url('./scss/_box.scss');
@import url('./scss/_text.scss');
@import url('./scss/_animations.scss');
@import url('./scss/_radius.scss');
@import url('./scss/_textColors.scss');
@import url('./scss/_border.scss');
@import url('./scss/_backgroundImage.scss');
@import url('./scss/_boxShadow.scss');
@import url('./scss/_cursor.scss');
@import url('./scss/_hoverBackground.scss');
@import url('./scss/_reset.scss');
@import url('./scss/_zIndex.scss');

html {
  background-color: #090d12;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  background: #090d12;
  background-repeat: no-repeat;
  background-size: cover;
  overscroll-behavior: none;
}
html,
body,
#root {
  min-height: 100vh;
  color: #ffffff;
  font-size: 8px;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;

  .grecaptcha-badge {
    visibility: hidden;
  }

  @media (min-width: 1920px) {
    font-size: 10px;
  }

  font-weight: 400;
  letter-spacing: 0.016rem;
  font-family: Inter, sans-serif;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-ExtraBold.woff) format('woff');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Bold.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-SemiBold.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Public-Sans;
  src: url(./assets/fonts/PublicSans-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Public-Sans;
  src: url(./assets/fonts/PublicSans-SemiBold.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Regular.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Bold.woff) format('woff');
  font-weight: 700;
}
