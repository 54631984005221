.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-attachment-fixed {
  background-attachment: fixed;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}
