// Radius
.radius-0 {
  border-radius: 0 !important;
}

.radius-top {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-bottom {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.radius-right {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.radius-2 {
  border-radius: 0.2rem;
}

.radius-4 {
  border-radius: 0.4rem;
}

.radius-6 {
  border-radius: 0.6rem;
}

.radius-8 {
  border-radius: 0.8rem;
}

.radius-12 {
  border-radius: 1.2rem;
}

.radius-16 {
  border-radius: 1.6rem;
}

.radius-20 {
  border-radius: 2rem;
}

.radius-24 {
  border-radius: 2.4rem;
}

.radius-32 {
  border-radius: 3.2rem;
}

.radius-36 {
  border-radius: 3.6rem;
}

.radius-72 {
  border-radius: 7.2rem;
}

.radius-round {
  aspect-ratio: 1 / 1;
  border-radius: 50%;

  &:focus {
    border-radius: 50%;
  }
}

.radius-top-4 {
  border-radius: 0.4rem 0.4rem 0 0;
}

.radius-top-8 {
  border-radius: 0.8rem 0.8rem 0 0;
}

.radius-top-12 {
  border-radius: 1.2rem 1.2rem 0 0;
}

.radius-bottom-4 {
  border-radius: 0 0 0.4rem 0.4rem;
}

.radius-bottom-8 {
  border-radius: 0 0 0.8rem 0.8rem;
}

.radius-bottom-12 {
  border-radius: 0 0 1.2rem 1.2rem;
}

.radius-left-4 {
  border-radius: 0.4rem 0 0 0.4rem;
}

.radius-left-8 {
  border-radius: 0.8rem 0 0 0.8rem;
}

.radius-left-12 {
  border-radius: 1.2rem 0 0 1.2rem;
}

.radius-right-4 {
  border-radius: 0 0.4rem 0.4rem 0;
}

.radius-right-8 {
  border-radius: 0 0.8rem 0.8rem 0;
}

.radius-right-12 {
  border-radius: 0 1.2rem 1.2rem 0;
}
