.z-minus {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

.z-500 {
  z-index: 500;
}

.z-600 {
  z-index: 600;
}
