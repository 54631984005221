// Typography
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.leading-0 {
  line-height: 0;
}

.leading-full {
  line-height: 100%;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-pre {
  white-space: pre;
}

.underline {
  text-decoration: underline;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.word-break {
  word-break: break-word;
}

.lh-unset {
  line-height: unset;
}
