.color-neutral-1 {
  color: #eaedee;
}

.color-neutral-2 {
  color: #e4eaec;
}

.color-neutral-3 {
  color: #c6cfd8;
}

.color-neutral-4 {
  color: #8f96a3;
}

.color-neutral-5 {
  color: #6a7484;
}

.color-neutral-6 {
  color: #525a67;
}

.color-neutral-7 {
  color: #434d59;
}

.color-neutral-8 {
  color: #333b48;
}

.color-neutral-9 {
  color: #29313e;
}

.color-neutral-10 {
  color: #1f2630;
}

.color-neutral-11 {
  color: #171d25;
}

.color-neutral-12 {
  color: #10151b;
}

.color-primary-1 {
  color: #efffc4;
}

.color-primary-2 {
  color: #e3faa2;
}

.color-primary-3 {
  color: #dbff75;
}

.color-primary-4 {
  color: #c4f538;
}

.color-primary-5 {
  color: #aade16;
}

.color-primary-6 {
  color: #8cb617;
}

.color-primary-7 {
  color: #6c8f07;
}

.color-primary-8 {
  color: #526f04;
}

.color-primary-9 {
  color: #3c5004;
}

.color-primary-10 {
  color: #2d3c03;
}

.color-primary-11 {
  color: #202903;
}

.color-primary-12 {
  color: #1a2201;
}

.color-positive-1 {
  color: #bfffe8;
}

.color-positive-2 {
  color: #9fffdd;
}

.color-positive-3 {
  color: #76f1c5;
}

.color-positive-4 {
  color: #4be1ac;
}

.color-positive-5 {
  color: #36d39b;
}

.color-positive-6 {
  color: #2eab7e;
}

.color-positive-7 {
  color: #207658;
}

.color-positive-8 {
  color: #184938;
}

.color-positive-9 {
  color: #103628;
}

.color-negative-1 {
  color: #fabdbd;
}

.color-negative-2 {
  color: #ff9797;
}

.color-negative-3 {
  color: #ff8282;
}

.color-negative-4 {
  color: #ff6c6c;
}

.color-negative-5 {
  color: #f85757;
}

.color-negative-6 {
  color: #eb4b4b;
}

.color-negative-7 {
  color: #bc3535;
}

.color-negative-8 {
  color: #852323;
}

.color-negative-9 {
  color: #591e1e;
}

.color-warning-1 {
  color: #fff9eb;
}

.color-warning-2 {
  color: #fff1d6;
}

.color-warning-3 {
  color: #feeac1;
}

.color-warning-4 {
  color: #fedc98;
}

.color-warning-5 {
  color: #ffc147;
}

.color-warning-6 {
  color: #ffb41e;
}

.color-warning-7 {
  color: #d09519;
}

.color-warning-8 {
  color: #a37314;
}

.color-warning-9 {
  color: #75520e;
}
