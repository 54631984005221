@mixin breakpoint-sm {
  @media (min-width: 0px) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (min-width: 1920px) {
    @content;
  }
}
