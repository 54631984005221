.linkWrap {
  grid-gap: 3.2rem 12rem;

  &.isPromo {
    grid-gap: 3.2rem 5rem;
  }
}

.link {
  &:hover {
    * {
      color: var(--color-primary-5);
    }
  }
}
