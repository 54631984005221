.text-font-mono {
  font-family: 'Azeret Mono';
}

.text-font-sf-ui {
  font-family: Inter;
}

.text-l10 {
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.14px;
}
.text-l12 {
  font-weight: 200;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.16px;
}
.text-l14 {
  font-weight: 200;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.18px;
}
.text-l16 {
  font-weight: 200;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
}
.text-l18 {
  font-weight: 200;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.22px;
}
.text-l20 {
  font-weight: 200;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.24px;
}
.text-l24 {
  font-weight: 200;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.3px;
}
.text-l26 {
  font-weight: 200;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.34px;
}
.text-l32 {
  font-weight: 200;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.38px;
}
.text-l40 {
  font-weight: 200;
  font-size: 4rem;
  line-height: 4.6rem;
  letter-spacing: 0.46px;
}
.text-l52 {
  font-weight: 200;
  font-size: 5.2rem;
  line-height: 6rem;
  letter-spacing: 0.6px;
}
.text-l60 {
  font-weight: 200;
  font-size: 6rem;
  line-height: 6.6rem;
  letter-spacing: 0.66px;
}
.text-l100 {
  font-weight: 200;
  font-size: 10rem;
  line-height: 10.6rem;
  letter-spacing: 1.06px;
}
.text-l130 {
  font-weight: 200;
  font-size: 13rem;
  line-height: 13.6rem;
  letter-spacing: 1.36px;
}
.text-r10 {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.14px;
}
.text-r12 {
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.16px;
}
.text-r14 {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.18px;
}
.text-r16 {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
}
.text-r18 {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.22px;
}
.text-r20 {
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.24px;
}
.text-r24 {
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.3px;
}
.text-r26 {
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.34px;
}
.text-r32 {
  font-weight: 300;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.38px;
}
.text-r40 {
  font-weight: 300;
  font-size: 4rem;
  line-height: 4.6rem;
  letter-spacing: 0.46px;
}
.text-r52 {
  font-weight: 300;
  font-size: 5.2rem;
  line-height: 6rem;
  letter-spacing: 0.6px;
}
.text-r60 {
  font-weight: 300;
  font-size: 6rem;
  line-height: 6.6rem;
  letter-spacing: 0.66px;
}
.text-r100 {
  font-weight: 300;
  font-size: 10rem;
  line-height: 10.6rem;
  letter-spacing: 1.06px;
}
.text-r130 {
  font-weight: 300;
  font-size: 13rem;
  line-height: 13.6rem;
  letter-spacing: 1.36px;
}
.text-m10 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.14px;
}
.text-m12 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.16px;
}
.text-m14 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.18px;
}
.text-m16 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
}
.text-m18 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.22px;
}
.text-m20 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.24px;
}
.text-m24 {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.3px;
}
.text-m26 {
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.34px;
}
.text-m32 {
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.38px;
}
.text-m40 {
  font-weight: 400;
  font-size: 4rem;
  line-height: 4.6rem;
  letter-spacing: 0.46px;
}
.text-m52 {
  font-weight: 400;
  font-size: 5.2rem;
  line-height: 6rem;
  letter-spacing: 0.6px;
}
.text-m60 {
  font-weight: 400;
  font-size: 6rem;
  line-height: 6.6rem;
  letter-spacing: 0.66px;
}
.text-m100 {
  font-weight: 400;
  font-size: 10rem;
  line-height: 10.6rem;
  letter-spacing: 1.06px;
}
.text-m130 {
  font-weight: 400;
  font-size: 13rem;
  line-height: 13.6rem;
  letter-spacing: 1.36px;
}
.text-s10 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.14px;
}
.text-s12 {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.16px;
}
.text-s14 {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.18px;
}
.text-s16 {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
}
.text-s18 {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.22px;
}
.text-s20 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.24px;
}
.text-s24 {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.3px;
}
.text-s26 {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.34px;
}
.text-s32 {
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.38px;
}
.text-s40 {
  font-weight: 500;
  font-size: 4rem;
  line-height: 4.6rem;
  letter-spacing: 0.46px;
}
.text-s52 {
  font-weight: 500;
  font-size: 5.2rem;
  line-height: 6rem;
  letter-spacing: 0.6px;
}
.text-s60 {
  font-weight: 500;
  font-size: 6rem;
  line-height: 6.6rem;
  letter-spacing: 0.66px;
}
.text-s100 {
  font-weight: 500;
  font-size: 10rem;
  line-height: 10.6rem;
  letter-spacing: 1.06px;
}
.text-s130 {
  font-weight: 500;
  font-size: 13rem;
  line-height: 13.6rem;
  letter-spacing: 1.36px;
}
.text-b10 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.14px;
}
.text-b12 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.16px;
}
.text-b14 {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: 0.18px;
}
.text-b16 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.2px;
}
.text-b18 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.22px;
}
.text-b20 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.24px;
}
.text-b24 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: 0.3px;
}
.text-b26 {
  font-weight: 600;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.34px;
}
.text-b32 {
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: 0.38px;
}
.text-b40 {
  font-weight: 600;
  font-size: 4rem;
  line-height: 4.6rem;
  letter-spacing: 0.46px;
}
.text-b52 {
  font-weight: 600;
  font-size: 5.2rem;
  line-height: 6rem;
  letter-spacing: 0.6px;
}
.text-b60 {
  font-weight: 600;
  font-size: 6rem;
  line-height: 6.6rem;
  letter-spacing: 0.66px;
}
.text-b100 {
  font-weight: 600;
  font-size: 10rem;
  line-height: 10.6rem;
  letter-spacing: 1.06px;
}
.text-b130 {
  font-weight: 600;
  font-size: 13rem;
  line-height: 13.6rem;
  letter-spacing: 1.36px;
}
