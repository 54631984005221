/**
 * Code generation below:
 */

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 0.4rem;
}

.p-8 {
  padding: 0.8rem;
}

.p-6 {
  padding: 0.6rem;
}

.p-12 {
  padding: 1.2rem;
}

.p-16 {
  padding: 1.6rem;
}

.p-20 {
  padding: 2rem;
}

.p-24 {
  padding: 2.4rem;
}

.p-28 {
  padding: 2.8rem;
}

.p-32 {
  padding: 3.2rem;
}

.p-36 {
  padding: 3.6rem;
}

.p-40 {
  padding: 4rem;
}

.p-48 {
  padding: 4.8rem;
}

.p-56 {
  padding: 5.6rem;
}

.p-60 {
  padding: 6rem;
}

.p-64 {
  padding: 6.4rem;
}

.p-72 {
  padding: 7.2rem;
}

.p-80 {
  padding: 8rem;
}

.p-96 {
  padding: 9.6rem;
}

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.px-6 {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.px-28 {
  padding-left: 2.8rem;
  padding-right: 2.8rem;
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.px-36 {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
}

.px-40 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-48 {
  padding-left: 4.8rem;
  padding-right: 4.8rem;
}

.px-56 {
  padding-left: 5.6rem;
  padding-right: 5.6rem;
}

.px-60 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-64 {
  padding-left: 6.4rem;
  padding-right: 6.4rem;
}

.px-72 {
  padding-left: 7.2rem;
  padding-right: 7.2rem;
}

.px-80 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-96 {
  padding-left: 9.6rem;
  padding-right: 9.6rem;
}

.px-100 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-120 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.py-28 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.py-36 {
  padding-top: 3.6rem;
  padding-bottom: 3.6rem;
}

.py-40 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-48 {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
}

.py-56 {
  padding-top: 5.6rem;
  padding-bottom: 5.6rem;
}

.py-60 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-64 {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
}

.py-72 {
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
}

.py-80 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-84 {
  padding-top: 8.4rem;
  padding-bottom: 8.4rem;
}

.py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
}

.pt-4 {
  padding-top: 0.4rem;
}

.pt-8 {
  padding-top: 0.8rem;
}

.pt-6 {
  padding-top: 0.6rem;
}

.pt-12 {
  padding-top: 1.2rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pt-20 {
  padding-top: 2rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pt-32 {
  padding-top: 3.2rem;
}

.pt-36 {
  padding-top: 3.6rem;
}

.pt-40 {
  padding-top: 4rem;
}

.pt-48 {
  padding-top: 4.8rem;
}

.pt-52 {
  padding-top: 5.2rem;
}

.pt-56 {
  padding-top: 5.6rem;
}

.pt-60 {
  padding-top: 6rem;
}

.pt-64 {
  padding-top: 6.4rem;
}

.pt-72 {
  padding-top: 7.2rem;
}

.pt-80 {
  padding-top: 8rem;
}

.pt-92 {
  padding-top: 9.2rem;
}

.pt-96 {
  padding-top: 9.6rem;
}


.pt-104 {
  padding-top: 10.4rem;
}

.pb-4 {
  padding-bottom: 0.4rem;
}

.pb-8 {
  padding-bottom: 0.8rem;
}

.pb-6 {
  padding-bottom: 0.6rem;
}

.pb-12 {
  padding-bottom: 1.2rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pb-20 {
  padding-bottom: 2rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pb-36 {
  padding-bottom: 3.6rem;
}

.pb-40 {
  padding-bottom: 4rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.pb-52 {
  padding-bottom: 5.2rem;
}

.pb-56 {
  padding-bottom: 5.6rem;
}

.pb-60 {
  padding-bottom: 6rem;
}

.pb-64 {
  padding-bottom: 6.4rem;
}

.pb-72 {
  padding-bottom: 7.2rem;
}

.pb-80 {
  padding-bottom: 8rem;
}

.pb-92 {
  padding-bottom: 9.2rem;
}

.pb-96 {
  padding-bottom: 9.6rem;
}

.pb-120 {
  padding-bottom: 12rem;
}

.pr-4 {
  padding-right: 0.4rem;
}

.pr-8 {
  padding-right: 0.8rem;
}

.pr-6 {
  padding-right: 0.6rem;
}

.pr-12 {
  padding-right: 1.2rem;
}

.pr-16 {
  padding-right: 1.6rem;
}

.pr-20 {
  padding-right: 2rem;
}

.pr-24 {
  padding-right: 2.4rem;
}

.pr-32 {
  padding-right: 3.2rem;
}

.pr-36 {
  padding-right: 3.6rem;
}

.pr-40 {
  padding-right: 4rem;
}

.pr-48 {
  padding-right: 4.8rem;
}

.pr-56 {
  padding-right: 5.6rem;
}

.pr-60 {
  padding-right: 6rem;
}

.pr-64 {
  padding-right: 6.4rem;
}

.pr-72 {
  padding-right: 7.2rem;
}

.pr-80 {
  padding-right: 8rem;
}

.pr-96 {
  padding-right: 9.6rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-4 {
  padding-left: 0.4rem;
}

.pl-8 {
  padding-left: 0.8rem;
}

.pl-6 {
  padding-left: 0.6rem;
}

.pl-12 {
  padding-left: 1.2rem;
}

.pl-16 {
  padding-left: 1.6rem;
}

.pl-20 {
  padding-left: 2rem;
}

.pl-24 {
  padding-left: 2.4rem;
}

.pl-28 {
  padding-left: 2.8rem;
}

.pl-32 {
  padding-left: 3.2rem;
}

.pl-36 {
  padding-left: 3.6rem;
}

.pl-40 {
  padding-left: 4rem;
}

.pl-48 {
  padding-left: 4.8rem;
}

.pl-56 {
  padding-left: 5.6rem;
}

.pl-60 {
  padding-left: 6rem;
}

.pl-64 {
  padding-left: 6.4rem;
}

.pl-72 {
  padding-left: 7.2rem;
}

.pl-80 {
  padding-left: 8rem;
}

.pl-96 {
  padding-left: 9.6rem;
}

.m-4 {
  margin: 0.4rem;
}

.m-8 {
  margin: 0.8rem;
}

.m-6 {
  margin: 0.6rem;
}

.m-12 {
  margin: 1.2rem;
}

.m-16 {
  margin: 1.6rem;
}

.m-20 {
  margin: 2rem;
}

.m-24 {
  margin: 2.4rem;
}

.m-32 {
  margin: 3.2rem;
}

.m-36 {
  margin: 3.6rem;
}

.m-40 {
  margin: 4rem;
}

.m-48 {
  margin: 4.8rem;
}

.m-56 {
  margin: 5.6rem;
}

.m-60 {
  margin: 6rem;
}

.m-64 {
  margin: 6.4rem;
}

.m-72 {
  margin: 7.2rem;
}

.m-80 {
  margin: 8rem;
}

.m-96 {
  margin: 9.6rem;
}

.mx-auto {
  margin: 0 auto;
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.mx-6 {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
}

.mx-32 {
  margin-left: 3.2rem;
  margin-right: 3.2rem;
}

.mx-36 {
  margin-left: 3.6rem;
  margin-right: 3.6rem;
}

.mx-40 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem;
}

.mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem;
}

.mx-60 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mx-64 {
  margin-left: 6.4rem;
  margin-right: 6.4rem;
}

.mx-72 {
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}

.mx-80 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mx-96 {
  margin-left: 9.6rem;
  margin-right: 9.6rem;
}

.my-4 {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.my-6 {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.my-36 {
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;
}

.my-40 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}

.my-56 {
  margin-top: 5.6rem;
  margin-bottom: 5.6rem;
}

.my-60 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-64 {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
}

.my-72 {
  margin-top: 7.2rem;
  margin-bottom: 7.2rem;
}

.my-80 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-96 {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mt-28 {
  margin-top: 2.8rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mt-36 {
  margin-top: 3.6rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mt-52 {
  margin-top: 5.2rem;
}

.mt-56 {
  margin-top: 5.6rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-64 {
  margin-top: 6.4rem;
}

.mt-72 {
  margin-top: 7.2rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mt-92 {
  margin-top: 9.2rem;
}

.mt-96 {
  margin-top: 9.6rem;
}

.mt-100 {
  margin-top: 10rem;
}

.mt-160 {
  margin-top: 16rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.mb-12 {
  margin-bottom: 1.2rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mb-28 {
  margin-bottom: 2.8rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mb-36 {
  margin-bottom: 3.6rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mb-56 {
  margin-bottom: 5.6rem;
}

.mb-60 {
  margin-bottom: 6rem;
}

.mb-64 {
  margin-bottom: 6.4rem;
}

.mb-72 {
  margin-bottom: 7.2rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

.mb-96 {
  margin-bottom: 9.6rem;
}

.mb-100 {
  margin-bottom: 10rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.mr-6 {
  margin-right: 0.6rem;
}

.mr-12 {
  margin-right: 1.2rem;
}

.mr-16 {
  margin-right: 1.6rem;
}

.mr-20 {
  margin-right: 2rem;
}

.mr-24 {
  margin-right: 2.4rem;
}

.mr-32 {
  margin-right: 3.2rem;
}

.mr-36 {
  margin-right: 3.6rem;
}

.mr-40 {
  margin-right: 4rem;
}

.mr-48 {
  margin-right: 4.8rem;
}

.mr-56 {
  margin-right: 5.6rem;
}

.mr-60 {
  margin-right: 6rem;
}

.mr-64 {
  margin-right: 6.4rem;
}

.mr-72 {
  margin-right: 7.2rem;
}

.mr-80 {
  margin-right: 8rem;
}

.mr-96 {
  margin-right: 9.6rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.ml-6 {
  margin-left: 0.6rem;
}

.ml-12 {
  margin-left: 1.2rem;
}

.ml-16 {
  margin-left: 1.6rem;
}

.ml-20 {
  margin-left: 2rem;
}

.ml-24 {
  margin-left: 2.4rem;
}

.ml-32 {
  margin-left: 3.2rem;
}

.ml-36 {
  margin-left: 3.6rem;
}

.ml-40 {
  margin-left: 4rem;
}

.ml-48 {
  margin-left: 4.8rem;
}

.ml-56 {
  margin-left: 5.6rem;
}

.ml-60 {
  margin-left: 6rem;
}

.ml-64 {
  margin-left: 6.4rem;
}

.ml-72 {
  margin-left: 7.2rem;
}

.ml-80 {
  margin-left: 8rem;
}

.ml-96 {
  margin-left: 9.6rem;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mt-inherit {
  margin-top: inherit;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
